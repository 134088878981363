import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`The idea`}</h5>
    <p>{`As I'm listening a lot to music on `}<strong parentName="p">{`soundcloud`}</strong>{` and almost every track there has some nice
`}<strong parentName="p">{`artwork`}</strong>{`.`}</p>
    <p>{`I was wondering how much effort it is to create these. I guess it is a lot, either you hire a artist
to create one for you or you do it yourself. In both cases it takes some `}<strong parentName="p">{`time`}</strong>{` and probably also
`}<strong parentName="p">{`money`}</strong>{` to do so. So I thought let's make this more easy by providing a web page where artists can
create an artwork in seconds for free.`}</p>
    <p>{`To create the artwork I wanted to use `}<strong parentName="p">{`p5.js`}</strong>{` and create a random image every time a user enters the webpage.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      